import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b346f9b8 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _4e70cbd7 = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _6d77b3f5 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _b7e85c6c = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _6f619679 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _55b70aac = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _2ecac7c6 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _65af1254 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _79e7db4b = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _020ba526 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _23753969 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _b3f1dd38 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _52cec53c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _51abb956 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _ca7c2dfa = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _31b81e0c = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _4498d049 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _1b83a435 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _142e7f98 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _4082395a = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _6c30a83b = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _c4a149d4 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _2074c9ce = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _55418688 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _98d08dac = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _72ed98a0 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _26ef9623 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _abf48f16 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _6db43eb3 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _3a7495a5 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _1df7c48a = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _0e283fca = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _d9f63fec = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _0f3bb5de = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _36957b03 = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _4ff5ebf9 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _849c39c2 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _f87de9de = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _3b8aee88 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _31d89258 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _5dca7f6f = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _cfa615cc = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _43ff17ee = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _cb7af768 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _1c9ff880 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _9c255f40 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _931d5e24 = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _844b0e6c = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _f8bcd064 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _0e0e42a8 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _141886a6 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _1cf01c45 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _692de055 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _20e67cc0 = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _76f228b8 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _29943867 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _62663d3e = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _192669dc = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _ee5682a6 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _39f5541e = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _edb1bbb6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _b346f9b8,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _4e70cbd7,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _6d77b3f5,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _b7e85c6c,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _6f619679,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _55b70aac,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _2ecac7c6,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _65af1254,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _79e7db4b,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _020ba526,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _23753969,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _b3f1dd38,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _52cec53c,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _51abb956,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _ca7c2dfa,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _31b81e0c,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _4498d049,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _1b83a435,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _142e7f98,
    name: "my-team"
  }, {
    path: "/news",
    component: _4082395a,
    name: "news"
  }, {
    path: "/notifications",
    component: _6c30a83b,
    name: "notifications"
  }, {
    path: "/payment-updates",
    component: _c4a149d4,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _2074c9ce,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _55418688,
    name: "profile"
  }, {
    path: "/profiles",
    component: _98d08dac,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _72ed98a0,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _26ef9623,
    name: "settings"
  }, {
    path: "/stats",
    component: _abf48f16,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _6db43eb3,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _3a7495a5,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _1df7c48a,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _0e283fca,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _d9f63fec,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _0f3bb5de,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _36957b03,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _4ff5ebf9,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _849c39c2,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _f87de9de,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _3b8aee88,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _31d89258,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _5dca7f6f,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _cfa615cc,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _43ff17ee,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _cb7af768,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _1c9ff880,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _9c255f40,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/account-requests/:request?",
    component: _931d5e24,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _844b0e6c,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _f8bcd064,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _0e0e42a8,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _141886a6,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _1cf01c45,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _692de055,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _20e67cc0,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _76f228b8,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _29943867,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _62663d3e,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _192669dc,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _ee5682a6,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _39f5541e,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _edb1bbb6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
